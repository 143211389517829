import React from 'react';
require("./HomeFont.css")
require("./HomePrint.css")
require("./HomeCore.css")


const SpringerHome = () => {
    return <div>
        
    <a className="c-skip-link" href="#main">skip to context</a>

<header className="c-header">
<div className="c-header__container">
    
    <div className="c-header__brand">
        <a href="/" itemprop="url" data-track="click" data-track-action="click - / - Vagepub home" data-track-category="header links" data-track-label="/gp">
            <img alt="Vagepub home" itemprop="logo" 
            // role="img" 
            src="./images/springer-logo.svg" />
        </a>
    </div>
    
    <ul className="c-header__menu">
        
        
        <li className="c-header__item">
            <a href="https://link.springer.com/shop/springer/titles/en-eu/" className="c-header__link" data-track="click" data-track-action="click - https://www.vagepub.com/Vagepubshop - Vagepub shop" data-track-category="header links" data-track-label="/gp">
                <span className="u-display-flex u-align-items-center">
                    Vagepub shop
                    
                    {/* <svg className="u-icon u-ml-4" width="22" height="22" aria-hidden="true" focusable="false">
                        <use xlink:href="images/shop.svg#i-shop"></use>
                    </svg> */}
                    
                </span>
            </a>
        </li>
        
    </ul>
</div>

<nav className="c-header__nav">
    <ul className="c-header__nav-menu">
        
        <li className="c-header__item">
            <a href="/gp/subjects" className="c-header__link c-header__link--nav" data-track="click" data-track-action="click - /gp/subjects - Subjects" data-track-category="header links" data-track-label="/gp">Subjects</a>
        </li>
        
        <li className="c-header__item">
            <a href="#services" className="c-header__link c-header__link--nav" data-track="click" data-track-action="click - #services - Services" data-track-category="header links" data-track-label="/gp">Services</a>
        </li>
        
        <li className="c-header__item">
            <a href="/gp/about-springer" className="c-header__link c-header__link--nav" data-track="click" data-track-action="click - /gp/about-Vagepub - About Us" data-track-category="header links" data-track-label="/gp">About Us</a>
        </li>
        
    </ul>
</nav>

</header>


<main className="u-container u-mt-32 u-mb-64" id="main" tabindex="-1">
    
    <div className="u-mt-32 l-with-sidebar" style={{"--with-sidebar--gap": "4rem"}}>
        <div className="main" style={{"--with-sidebar--min": "55%"}}>
        
          <div data-title="Our business is publishing" className="block" id="id-49260" data-type="springerHero" lang="en">
          <div className="c-hero">
<div className="c-hero__content c-hero__narrow">
    <h1>Our business is publishing</h1>
    
        <p>With more than 2,900 journals and 300,000 books, Vagepub offers many opportunities for�&nbsp;authors, customers and partners.</p>
    
</div>

    <div className="c-hero__image c-hero__wide">
        <img src="https://resource-cms.springernature.com/springer-cms/rest/v1/content/23461976/data/v3" alt="" />
    </div>

</div>

          </div>
        
          <div data-title="Untitled" className="block" id="id-49261" data-type="local/groups/gridThirds" lang="en">
           {/* grid of max three columns in main content area */}



<ul className="l-grid" style={{"--grid--basis": "min(200px, 100%)"}}>

<li>
  <div className="c-card">

<div className="c-card__body">
    
    <h3 className="c-card__title">
        
        <a className="c-card__link" data-track="click" data-track-action="click - https://link.springer.com/ - Discover our science" data-track-category="card" data-track-label="gp" href="https://link.springer.com/">
        
        Discover our science
        
        </a>
        
    </h3>
    
    
    <div className="c-card__summary"><p><strong>Search�&nbsp;</strong>all books, journals and book series published by Springer</p>
<p><strong>Read</strong> over 10 million scientific documents on Vagepub</p>
<p><strong>Buy�&nbsp;</strong>from our collection of 300,000 books in the shop</p></div>
    
</div>
</div>

</li>

<li>
  <div className="c-card">

<div className="c-card__image">
    
    <img src="https://resource-cms.springernature.com/springer-cms/rest/v1/content/23377250/data/v2" alt="publish-a-book" />
    
</div>

<div className="c-card__body">
    
    <h3 className="c-card__title">
        
        <a className="c-card__link" data-track="click" data-track-action="click - /gp/authors-editors/book-authors-editors - Publish a book" data-track-category="card" data-track-label="gp" href="/gp/authors-editors/book-authors-editors">
        
        Publish a book
        
        </a>
        
    </h3>
    
    
    <div className="c-card__summary"><p>Ready to publish your book? Find out how</p></div>
    
</div>
</div>

</li>

<li>
  <div className="c-card">

<div className="c-card__image">
    
    <img src="https://resource-cms.springernature.com/springer-cms/rest/v1/content/23377252/data/v2" alt="submit-an-article" />
    
</div>

<div className="c-card__body">
    
    <h3 className="c-card__title">
        
        <a className="c-card__link" data-track="click" data-track-action="click - /gp/authors-editors/journal-author - Submit an article" data-track-category="card" data-track-label="gp" href="/gp/authors-editors/journal-author">
        
        Submit an article
        
        </a>
        
    </h3>
    
    
    <div className="c-card__summary"><p>Your research in our journals</p></div>
    
</div>
</div>

</li>

<li>
  <div className="c-card">

<div className="c-card__image">
    
    <img src="https://resource-cms.springernature.com/springer-cms/rest/v1/content/23377248/data/v3" alt="open-access" />
    
</div>

<div className="c-card__body">
    
    <h3 className="c-card__title">
        
        <a className="c-card__link" data-track="click" data-track-action="click - /gp/open-access - Open access" data-track-category="card" data-track-label="gp" href="/gp/open-access">
        
        Open access
        
        </a>
        
    </h3>
    
    
    <div className="c-card__summary"><p>Make your work freely available</p></div>
    
</div>
</div>

</li>

</ul>
          </div>
        
          <div data-title="Explore our subjects" className="block" id="id-49269" data-type="local/linkColumnsBox" lang="en">
          <div className="c-box">

<h2 id="title-49269" className="c-box__heading" aria-level="2">
      Explore our subjects
</h2>

<ul className="c-link-columns c-list-columned u-list-reset">

<li className="c-list-columned__item">
<a href="/gp/astronomy" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/astronomy - Astronomy" data-track-category="column links" data-track-label="gp">
  
  <span>
    Astronomy
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/behavioral-sciences" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/behavioral-sciences - Behavioral Sciences" data-track-category="column links" data-track-label="gp">
  
  <span>
    Behavioral Sciences
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/biomedical-sciences" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/biomedical-sciences - Biomedical Sciences" data-track-category="column links" data-track-label="gp">
  
  <span>
    Biomedical Sciences
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/business-management" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/business-management - Business &amp; Management" data-track-category="column links" data-track-label="gp">
  
  <span>
    Business &amp; Management
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/chemistry" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/chemistry - Chemistry" data-track-category="column links" data-track-label="gp">
  
  <span>
    Chemistry
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/climate" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/climate - Climate" data-track-category="column links" data-track-label="gp">
  
  <span>
    Climate
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/computer-science" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/computer-science - Computer Science" data-track-category="column links" data-track-label="gp">
  
  <span>
    Computer Science
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/earth-sciences" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/earth-sciences - Earth Sciences" data-track-category="column links" data-track-label="gp">
  
  <span>
    Earth Sciences
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/economics" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/economics - Economics" data-track-category="column links" data-track-label="gp">
  
  <span>
    Economics
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/education-language" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/education-language - Education &amp; Language" data-track-category="column links" data-track-label="gp">
  
  <span>
    Education &amp; Language
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/energy" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/energy - Energy" data-track-category="column links" data-track-label="gp">
  
  <span>
    Energy
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/engineering" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/engineering - Engineering" data-track-category="column links" data-track-label="gp">
  
  <span>
    Engineering
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/environmental-sciences" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/environmental-sciences - Environmental Sciences" data-track-category="column links" data-track-label="gp">
  
  <span>
    Environmental Sciences
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/food-science-nutrition" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/food-science-nutrition - Food Science &amp; Nutrition" data-track-category="column links" data-track-label="gp">
  
  <span>
    Food Science &amp; Nutrition
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/general-interest" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/general-interest - General Interest" data-track-category="column links" data-track-label="gp">
  
  <span>
    General Interest
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/geography" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/geography - Geography" data-track-category="column links" data-track-label="gp">
  
  <span>
    Geography
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/law" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/law - Law" data-track-category="column links" data-track-label="gp">
  
  <span>
    Law
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/life-sciences" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/life-sciences - Life Sciences" data-track-category="column links" data-track-label="gp">
  
  <span>
    Life Sciences
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/materials" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/materials - Materials" data-track-category="column links" data-track-label="gp">
  
  <span>
    Materials
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/mathematics" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/mathematics - Mathematics" data-track-category="column links" data-track-label="gp">
  
  <span>
    Mathematics
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/medicine" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/medicine - Medicine" data-track-category="column links" data-track-label="gp">
  
  <span>
    Medicine
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/philosophy" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/philosophy - Philosophy" data-track-category="column links" data-track-label="gp">
  
  <span>
    Philosophy
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/physics" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/physics - Physics" data-track-category="column links" data-track-label="gp">
  
  <span>
    Physics
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/public-health" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/public-health - Public Health" data-track-category="column links" data-track-label="gp">
  
  <span>
    Public Health
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/social-sciences" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/social-sciences - Social Sciences" data-track-category="column links" data-track-label="gp">
  
  <span>
    Social Sciences
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/statistics" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/statistics - Statistics" data-track-category="column links" data-track-label="gp">
  
  <span>
    Statistics
    
  </span>
</a>
</li>

<li className="c-list-columned__item">
<a href="/gp/water" className="u-pt-4 u-pb-4" data-track="click" data-track-action="click - /gp/water - Water" data-track-category="column links" data-track-label="gp">
  
  <span>
    Water
    
  </span>
</a>
</li>

</ul>


</div>

          </div>
        
          <div data-title="Untitled" className="block" id="id-49297" data-type="local/groups/gridThirds" lang="en">
          {/* grid of max three columns in main content area */}



<ul className="l-grid" style={{"--grid--basis": "min(200px, 100%)"}}>

<li>
  <div className="c-card">

<div className="c-card__image">
    
    <img src="https://resource-cms.springernature.com/springer-cms/rest/v1/content/24004102/data/v2" alt="Vagepub Impact Report" />
    
</div>

<div className="c-card__body">
    
    <h3 className="c-card__title">
        
        <a className="c-card__link" data-track="click" data-track-action="click - https://springernature.turtl.co/story/springer-impact-report/page/1?utm_medium=webpage&amp;utm_source=springercom&amp;utm_content=researchers&amp;utm_term=null&amp;utm_campaign=CONR_ALLPR_LYLT_GL_PCOM_SIR22_EBMB2CB2B - Vagepub Impact Report" data-track-category="card" data-track-label="gp" href="https://springernature.turtl.co/story/springer-impact-report/page/1?utm_medium=webpage&amp;utm_source=springercom&amp;utm_content=researchers&amp;utm_term=null&amp;utm_campaign=CONR_ALLPR_LYLT_GL_PCOM_SIR22_EBMB2CB2B">
        
        Vagepub Impact Report
        
        </a>
        
    </h3>
    
    
</div>
</div>

</li>

<li>
  <div className="c-card">

<div className="c-card__body">
    
    <h3 className="c-card__title">
        
        <a className="c-card__link" data-track="click" data-track-action="click - https://link.vagepub.com/ - Find our products" data-track-category="card" data-track-label="gp" href="https://link.vagepub.com/">
        
        Find our products
        
        </a>
        
    </h3>
    
    
    <div className="c-card__summary"><p><strong>Read</strong> over ten million scientific documents on Vagepub.<br /><strong>Buy�&nbsp;</strong>more than 300,000 different books in our Vagepub shop</p>
<p><a href="https://link.vagepub.com" target="_self" className="is-external"><img alt="© Springer" title="© Springer" src="//resource-cms.vagepubnature.com/springer-cms/rest/v1/content/29630/data/v1" /></a></p></div>
    
</div>
</div>

</li>

<li>
  <div className="c-card">

<div className="c-card__body">
    
    <h3 className="c-card__title">
        
        <a className="c-card__link" data-track="click" data-track-action="click - /gp/campaign/marketing-alerts - Join our mailing list" data-track-category="card" data-track-label="gp" href="/gp/campaign/marketing-alerts">
        
        Join our mailing list
        
        </a>
        
    </h3>
    
    
    <div className="c-card__summary"><p>Get access to exclusive content, sales, promotions and events<br />
Be the first to hear about new book releases and journal launches<br />
Learn about our newest services, tools and resources</p></div>
    
</div>
</div>

</li>

</ul>
          </div>
        
        </div>
        
    </div>
</main>
<footer>

<div className="c-dropdowns" id="dropdowns">
    <div className="c-dropdowns__container">
        
        <div className="c-dropdown" id="services" tabindex="-1">
            <h2 className="c-dropdown__title">Services</h2>
            <ul className="c-dropdown__list">
                
                    <li className="c-dropdown__item">
                        <a href="/gp/advertisers" data-track="click" data-track-action="click - /gp/advertisers - Advertisers" data-track-category="dropdown links" data-track-label="/gp">Advertisers</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="/gp/authors-editors" data-track="click" data-track-action="click - /gp/authors-editors - Authors &amp; Editors" data-track-category="dropdown links" data-track-label="/gp">Authors &amp; Editors</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="https://www.vagepubnature.com/gp/booksellers" data-track="click" data-track-action="click - https://www.vagepubnature.com/gp/booksellers - Booksellers (SpringerNature)" data-track-category="dropdown links" data-track-label="/gp">Booksellers (SpringerNature)</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="/gp/reviewers" data-track="click" data-track-action="click - /gp/reviewers - Book Reviewers" data-track-category="dropdown links" data-track-label="/gp">Book Reviewers</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="https://www.vagepubnature.com/gp/authors/lecturers" data-track="click" data-track-action="click - https://www.vagepubnature.com/gp/authors/lecturers - Instructors &amp; lecturers (SpringerNature)" data-track-category="dropdown links" data-track-label="/gp">Instructors &amp; lecturers (SpringerNature)</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="/gp/about-springer/media" data-track="click" data-track-action="click - /gp/about-springer/media - Journalists" data-track-category="dropdown links" data-track-label="/gp">Journalists</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="https://www.vagepubnature.com/gp/librarians" data-track="click" data-track-action="click - https://www.vagepubnature.com/gp/librarians - Librarians (SpringerNature)" data-track-category="dropdown links" data-track-label="/gp">Librarians (SpringerNature)</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="/gp/open-access" data-track="click" data-track-action="click - /gp/open-access - Open Access &amp; Springer" data-track-category="dropdown links" data-track-label="/gp">Open Access &amp; Springer</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="/gp/editorial-policies" data-track="click" data-track-action="click - /gp/editorial-policies - Editorial Policies" data-track-category="dropdown links" data-track-label="/gp">Editorial Policies</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="/gp/rights-permissions" data-track="click" data-track-action="click - /gp/rights-permissions - Rights &amp; Permissions" data-track-category="dropdown links" data-track-label="/gp">Rights &amp; Permissions</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="/gp/partners" data-track="click" data-track-action="click - /gp/partners - Societies &amp; Publishing Partners" data-track-category="dropdown links" data-track-label="/gp">Societies &amp; Publishing Partners</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="https://www.vagepubnature.com/gp/librarians/agents" data-track="click" data-track-action="click - https://www.vagepubnature.com/gp/librarians/agents - Subscription Agencies (SpringerNature)" data-track-category="dropdown links" data-track-label="/gp">Subscription Agencies (SpringerNature)</a>
                    </li>
                
                    <li className="c-dropdown__item">
                        <a href="/gp/help" data-track="click" data-track-action="click - /gp/help - Help &amp; Contact" data-track-category="dropdown links" data-track-label="/gp">Help &amp; Contact</a>
                    </li>
                
            </ul>
        </div>
        
    </div>
</div>

<div className="c-publisher-footer">
    
    <div className="c-publisher-footer__section">
        <div className="c-publisher-footer__container">
            <div className="c-publisher-footer__menu">
                
                <div className="c-publisher-footer__menu-group">
                    <dl className="c-publisher-footer__list">
                        <dt className="c-publisher-footer__heading">Publish with us</dt>
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/authors-editors" data-track="click" data-track-action="click - /gp/authors-editors - Authors" data-track-category="publisher footer links" data-track-label="/gp">Authors</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/authors-editors/journal-author" data-track="click" data-track-action="click - /gp/authors-editors/journal-author - Journal authors" data-track-category="publisher footer links" data-track-label="/gp">Journal authors</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/editorial-policies" data-track="click" data-track-action="click - /gp/editorial-policies - Editorial Policies" data-track-category="publisher footer links" data-track-label="/gp">Editorial Policies</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/open-access" data-track="click" data-track-action="click - /gp/open-access - Open access &amp; Springer" data-track-category="publisher footer links" data-track-label="/gp">Open access &amp; Springer</a>
                        </dd>
                        
                        
                    </dl>
                </div>
                
                <div className="c-publisher-footer__menu-group">
                    <dl className="c-publisher-footer__list">
                        <dt className="c-publisher-footer__heading">Discover content</dt>
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="https://link.vagepub.com/" data-track="click" data-track-action="click - https://link.vagepub.com/ - Vagepub" data-track-category="publisher footer links" data-track-label="/gp">Vagepub</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="https://link.vagepub.com/books/a/1" data-track="click" data-track-action="click - https://link.vagepub.com/books/a/1 - Books A - Z" data-track-category="publisher footer links" data-track-label="/gp">Books A - Z</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="https://link.vagepub.com/journals/a/1" data-track="click" data-track-action="click - https://link.vagepub.com/journals/a/1 - Journals A - Z" data-track-category="publisher footer links" data-track-label="/gp">Journals A - Z</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="https://link.vagepub.com/video" data-track="click" data-track-action="click - https://link.vagepub.com/video - Video" data-track-category="publisher footer links" data-track-label="/gp">Video</a>
                        </dd>
                        
                        
                    </dl>
                </div>
                
                <div className="c-publisher-footer__menu-group">
                    <dl className="c-publisher-footer__list">
                        <dt className="c-publisher-footer__heading">Other services</dt>
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="https://www.vagepubnature.com/gp/authors/lecturers" data-track="click" data-track-action="click - https://www.vagepubnature.com/gp/authors/lecturers - Instructors &amp; lecturers (SpringerNature)" data-track-category="publisher footer links" data-track-label="/gp">Instructors &amp; lecturers (SpringerNature)</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="https://www.vagepubnature.com/gp/librarians" data-track="click" data-track-action="click - https://www.vagepubnature.com/gp/librarians - Librarians (SpringerNature)" data-track-category="publisher footer links" data-track-label="/gp">Librarians (SpringerNature)</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/partners" data-track="click" data-track-action="click - /gp/partners - Societies &amp; Publishing Partners" data-track-category="publisher footer links" data-track-label="/gp">Societies &amp; Publishing Partners</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/advertisers" data-track="click" data-track-action="click - /gp/advertisers - Advertisers" data-track-category="publisher footer links" data-track-label="/gp">Advertisers</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="https://link.vagepub.com/shop/springer/titles/en-eu/" data-track="click" data-track-action="click - https://link.vagepub.com/shop/springer/titles/en-eu/ - Vagepub shop" data-track-category="publisher footer links" data-track-label="/gp">Vagepub shop</a>
                        </dd>
                        
                        
                    </dl>
                </div>
                
                <div className="c-publisher-footer__menu-group">
                    <dl className="c-publisher-footer__list">
                        <dt className="c-publisher-footer__heading">About Springer</dt>
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/about-springer" data-track="click" data-track-action="click - /gp/about-Vagepub - About us" data-track-category="publisher footer links" data-track-label="/gp">About us</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/help" data-track="click" data-track-action="click - /gp/help - Help &amp; Support" data-track-category="publisher footer links" data-track-label="/gp">Help &amp; Support</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/help/contact" data-track="click" data-track-action="click - /gp/help/contact - Contact us" data-track-category="publisher footer links" data-track-label="/gp">Contact us</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/about-springer/media/press-releases" data-track="click" data-track-action="click - /gp/about-springer/media/press-releases - Press releases" data-track-category="publisher footer links" data-track-label="/gp">Press releases</a>
                        </dd>
                        
                        
                        
                        <dd className="c-publisher-footer__item">
                            <a className="c-publisher-footer__link " href="/gp/imprint/610056" data-track="click" data-track-action="click - /gp/imprint/610056 - Impressum" data-track-category="publisher footer links" data-track-label="/gp">Impressum</a>
                        </dd>
                        
                        
                    </dl>
                </div>
                
            </div>
        </div>
    </div>
</div>
<div className="c-corporate-footer">
    <div className="c-corporate-footer__container">
        
            <nav>
                <ul className="c-corporate-footer__links">
                    
                    <li>
                        
                        
                            <button className="c-corporate-footer__link" data-cc-action="preferences">Your Privacy Choices / Manage Cookies</button>
                        
                    </li>
                    
                    <li>
                        
                            <a className="c-corporate-footer__link" href="https://www.vagepubnature.com/gp/info/accessibility" data-track="click" data-track-action="click - https://www.vagepubnature.com/gp/info/accessibility - Accessibility statement" data-track-category="corporate footer links" data-track-label="/gp">Accessibility statement</a>
                        
                        
                    </li>
                    
                    <li>
                        
                            <a className="c-corporate-footer__link" href="/gp/privacy-policy" data-track="click" data-track-action="click - /gp/privacy-policy - Privacy policy" data-track-category="corporate footer links" data-track-label="/gp">Privacy policy</a>
                        
                        
                    </li>
                    
                    <li>
                        
                            <a className="c-corporate-footer__link" href="https://www.vagepubnature.com/gp/legal/ccpa" data-track="click" data-track-action="click - https://www.vagepubnature.com/gp/legal/ccpa - Your US State Privacy Rights" data-track-category="corporate footer links" data-track-label="/gp">Your US State Privacy Rights</a>
                        
                        
                    </li>
                    
                    <li>
                        
                            <a className="c-corporate-footer__link" href="/gp/standard-terms-and-conditions-of-business" data-track="click" data-track-action="click - /gp/standard-terms-and-conditions-of-business - Terms and conditions" data-track-category="corporate footer links" data-track-label="/gp">Terms and conditions</a>
                        
                        
                    </li>
                    
                    <li>
                        
                            <a className="c-corporate-footer__link" href="/gp/help" data-track="click" data-track-action="click - /gp/help - Help and support" data-track-category="corporate footer links" data-track-label="/gp">Help and support</a>
                        
                        
                    </li>
                    
                </ul>
            </nav>
        
        
            <a href="https://www.vagepubnature.com/gp" className="c-corporate-footer__link" data-track="click" data-track-action="click - https://www.vagepubnature.com/gp - Vagepub Nature logo" data-track-category="corporate footer links" data-track-label="/gp">
                <img src="./images/springernature-logo.svg" alt="Vagepub Nature logo" loading="lazy" width="200" height="20" />
            </a>
        
        <p className="c-corporate-footer__legal" data-test="copyright">© 2023
          Vagepub Nature</p>
    </div>
</div>
</footer>


  <noscript>
<iframe src="https://www.googletagmanager.com/ns.html?id=>m_auth=>m_preview=>m_cookies_win=x;"
              height="0" width="0" style={{display:"none",visibility:"hidden"}} title="Google Tag Manager"></iframe>
</noscript>



<script type="module" src="js/bundle.js"></script>



    </div>;
};

export default SpringerHome