import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FaArrowDown, FaArrowRight, FaArrowUp, FaBars, FaExpandAlt, FaSearch, FaShareSquare, FaShoppingCart, FaTimes, FaUser } from 'react-icons/fa';
import cartAdImg1 from '../../../shopping/advertsImages/2269347379381748666.gif'
import cartAdImg2 from '../../../shopping/advertsImages/5935391980210628819.gif'
import cartAdImg3 from '../../../shopping/images/logobg.png'
import cartAdImg4 from '../../../shopping/images/11524_2024_845_Fig1_HTML_1.png'
import cartAdImg5 from '../../../shopping/images/11524_2024_851_Fig1_HTML.png'
import cartAdImg6 from '../../../shopping/images/11524_2024_836_Fig1_HTML.png'
import cartAdImg7 from '../../../shopping/images/11524_2024_841_Fig1_HTML.png'
import cartAdImg8 from '../../../shopping/images/language-checker-large1x.webp'
import cartAdImg9 from '../../../shopping/images/editorial-right.GIF'
import currentCartItemImage from '../../../shopping/images/978-1-4939-6676-9.jpg'
import springerLogo from '../../../shopping/images/logobg.png'
import './SpringerJournal.css'
import { clientUrl2, url, url1, url2 } from '../../../Redux/Api';

const SpringerJournal = ({location}) => {
    const images = [cartAdImg1,cartAdImg2]
    const[currentImage, setCurrentImage] = useState([images[1]])
    const[preview, setPreview] = useState(false);
    const[show, setShow] = useState(false);
    const[upperLinkEbook,setUpperLinkEbook] = useState(false)
    const[upperLinkSoftCover,setUpperLinkSoftCover] = useState(false)
    const[upperLinkHardCover,setUpperLinkHardCover] = useState(false)
    const[currentItem, setCurrentItem] = useState(null);
    const[error, setError] = useState(null)
    const cartItems = []
    const{pub} = useParams()
    const user = JSON.parse(localStorage.getItem('user'))
    
    const[toggleMenu, setToggleMenu] = useState(false)
    const[screenWidth, setScreenWidth] = useState(window.innerWidth)
    const[open, setOpen] = useState(true);
    const[newState, setNewState] = useState({status:'',data:''});
    const[manuscripts, setManuscripts] = useState([]);
    const navigate = useNavigate()

    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
        setOpen(!open)
      }

      useEffect(() => {
        const changeWidth = () => {
          setScreenWidth(window.innerWidth);
        }
        window.addEventListener('resize', changeWidth)
        return () => {
            window.removeEventListener('resize', changeWidth)
        }
      }, [])
      useEffect(()=>{
        async function fetchData(){
            await fetch(`${url2}/manuscripts`,{
                mode:'cors',
                headers:{
                    'Content-Type':'application/json'
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    setNewState({status:'success',data:''})
                    return setManuscripts(resp.resp)
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'});
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    },[])

        useEffect(()=>{
            async function name() {
                await fetch(`${url2}/books`,{
                    mode:'cors',
                    method:'get',
                    headers:{
                        'Content-Type':'application/json'
                    }
                }).then(res=>res.json())
                .then(resp=>{
                    if(resp.resp){
                        const book = resp.resp.filter(item=>item?.pub?.toString()===pub.toString())
                        setCurrentItem(book[0]);
                    }
                }).catch(err=>{setError(err.message);console.log('last',err.message)})
            }
            name();
},[])

    var addedItem = {type:'hard-cover',name:'book name',title:'book title',authors:[],cost:50,number:20499}

    useEffect(()=>{
        for(let i=0; i<images.length;i++){
            setInterval(() => {
                setCurrentImage(images[i])           
            }, 1000);       
        }
    },[])

    const[search, setSearch] = useState('');
    const[searchData, setSearchData] = useState([]);

    useEffect(()=>{
        setSearchData(manuscripts.filter((item)=>{item.author.includes(search)}))
    },[search])

    useEffect(()=>{
        setManuscripts(searchData)
    },[searchData]);

    const handleSearch = ()=>{

    }

    useEffect(()=>{
        localStorage.setItem('cart',JSON.stringify(...cartItems,addedItem))
    },[upperLinkEbook,upperLinkSoftCover,upperLinkHardCover]);

    const[openSearch, setOpenSearch] = useState(false);
    const[addToCartState, setAddToCartState] = useState({state:false,name:'',cost:0});
    const searchParams = new URLSearchParams(window.location.search);
    
    return (<>
    {
        preview===false&&!addToCartState.state?
        <div className='cart-main'>
            <div className='cart-header'>
                <div className='cart-nav'>
                    <div className='cart-auth'><span className='site-title'><img src={springerLogo} alt='' className='logo-img' /></span> <div className='auth-link'>{user?
                    <Link to={`${clientUrl2}/${currentItem?.acronym?.toLowerCase()}`} className='authenticated'>
                        <FaUser />
                        <span>Account</span>
                    </Link>:<Link to={`${clientUrl2}/${currentItem?.acronym?.toLowerCase()}`} className='authenticated'>
                        <span>Login</span>
                    </Link>}</div></div>
                    <hr className='horizontal-line' />
                    <nav className='cart-items-nav-bar'>
            {(toggleMenu || screenWidth > 500) && (
            <ul className="cart-items-menu-container">
              <div className='cart-menu-items'>
                        <Link to={`${clientUrl2}/${currentItem?.acronym?.toLowerCase()}`} className='cart-link'>Find a journal</Link>
                        <Link to={`${clientUrl2}/${currentItem?.acronym?.toLowerCase()}`} className='cart-link'>Publish with us</Link>
                        <Link to={`${clientUrl2}/${currentItem?.acronym?.toLowerCase()}`} className='cart-link'>Track your reasearch</Link>
            </div>
          </ul>
            )}
            <div className='search-wrapper'>
            <button onClick={toggleNav} className="nav-btn">
                {
                    open?<div className='toggle-wrapper'><FaBars className='toggle-menu-open' /><span className='toggle-menu-text'>Menu</span>
                    
                    </div>:<><FaTimes className='toggle-menu-close' /></>
                }
            </button>
            </div>
            
          </nav></div>
          
            </div>
            {openSearch&&<div className='editorial-search-container'>
            <h1 className='editorial-search-title'>Search by keyword or author</h1>
            <input type='search' className='editorial-search-input' onChange={e=>setSearch(e.target.value)} />
            <img onClick={handleSearch} className='editorial-search-img' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAChklEQVR4nO2Zu24TQRSGPxeElgYbEVIYHoECCJdXQAIH+YKECA+AxEVESAl0wAsgUUEKpCiiIDFKDYGGS96AhhZSBZGQ2MFwpH+lKQxi8czYu+wnjbTy5f/3jPecOTOGgoKR5AAwDcwDa8A6sKPxBfig9y4DFUaQM0Ab6AC9vxz22WXgNCPAEWDFubku8Aq4BhwDysAeDbs+DlwHVvXZ5HsvgMPDCqIFfNWNfAPuAftTfN8Cuw9sSmMDaBCZOWc2F4DxAbQOAYuO3iyRuCvDH7ouedK9CuzGCqYlIzOsBdCfcoKpEzCxk5y4FcoEmHFyphrCYMXJiZCUnJxph1gneqowE4RnXJWwB5zyKdyWqJXYWFhpNs8ln21HRwtYmnViUGyd6aq9seuBuaKZeUl8Xsv7kg+xeYlZ2xGbG/J+7ENsTWLWO8VmUt7vfIitSyxmfiRU5P3Zh9i2xMaIz155f89LIBt5ebQ++kx22xTF5qS8rQznovw+8iE2LTHbnsbmjbzP+XpOkxbFS6uQskXpAPt8iS5rZqyRi8UDeVpL740TEt0aQht/1Ld425khX/v0fpj2M3k9D2FQ1cLU03Y0FLflYSeTB0OZNJzDBzso8M0F5/DhLIGZdYKZ8fSYlfRLJEHs6sSGWMEkOWOHbP/KhJMT7rDS2yQCdSdnNlWa06wzZZXYLacx7PYJpkEEqk41S4xX1VpMajEd06jotZvqndybXlBiN4cZDDqyWUr5t4JtD572aUZ/F0ydiJR1UPAEeK8Suq3F7RPwFniom/rTtqAxCsH4wsp7p08wF8lRMC0yyFSegqn9D8E0yWgwO0UwGSkAc2SU885jdoeMU4v5d3ZBwa8Z+Ak4rhMJLuUtjQAAAABJRU5ErkJggg=="></img>
            {
                search.length>0&&<img onClick={setSearch('')} className='editorial-search-img-cancel' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJUlEQVR4nO3YT0sCQRyH8YeuUu0KG/RHUG8efAceetFBJ6noEKUI6iG1wHolxcIYg6wi67Qzs3w/IHga92F09zeCiIiIiMg/6QEPwKXDNRvAHXBLhYbADzAHLhysdwo8W2ueUJEMmJoPfj9yZ/KdeDRrfQNdKpYCY3MBC+DKQUQHT46JsSO+fEYUxSwPjMkjnkKKsGNGVsw1u50BLyFGFMWsdsQEH7GR7Ik534poE7hkK+YmxoiNJjAxF/4BvJr3n0CLyCTAmwnIX+uYdqJ2Ic06fLWSgjtXdD/2dM/tN5pnSHrA0z3Y0aTMvBVsTFpiAg5mfHc9xnuNyaxT4qLkKbFhzv7eTocZMHN41PUWM6zLnw99E+Miwo65BwYO1xQRERER4c8v6Th7MMbV15kAAAAASUVORK5CYII="></img>
            }
          </div>}
    <div className='cart-body'>
    <div className='links-wrapper'>
    <div className='extra-links'>
            <a herf ='/' className='home-link'>Home</a>
            {/* <span className='book-caption'>{currentItem?.title}</span> */}
    </div>
    </div>
        <div className='left-current-cart-item'>
       <div className='left-current-cart-item-wrapper'>
        <div className='right-current-journal-item'>
            <a href={`${clientUrl2}/${currentItem?.acronym?.toLowerCase()}`} className='submit-script-link-title'>{currentItem?.title}</a>
            <div className='center-container'>
            <span className='submit-script-model'>{currentItem?.model}</span>
            <span className='submit-script-method'>{currentItem?.type}</span>
           
        </div>
       </div>
       </div>
        <a href={`${clientUrl2}/${currentItem?.acronym?.toLowerCase()}`} className="submit-script-btn" target="_blank" rel="noopener">
                    Submit your manuscript<span className="u-visually-hidden"> (this opens in a new tab)</span>
                    <img className='editorial-info-icon' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAoUlEQVR4nO2VMQ7DIAxF3yWMeqScOwwZ2qkZstODECFlQFFwTKBT8iUPSOa/L1sCeFSpAfgBsVA+6/WFnkkDBMU8AqMBkKqo04YDCfD9F8AB83Zn7g2QLPkCvHoC5MCcXgC3G0s654sfWwBSSG6WBpBWcw3glLGwG5GvBUhF8ktLfhuSNwEm4GMwvwyo0Q0A4eS5joZKHuqH0wIJm8cjzFoBlbCPZdUo6dUAAAAASUVORK5CYII=" />
                </a>
    </div>
    </div>
    <div className='middle-section-one'>
       <div className='editorial-left-section'>
       
       <div className='editorial-left-inner-section-container'>
       <span className='editors-title'>Overview</span>
       <p className='overview-content'>{currentItem?.overView}</p>
        <div className='editorial-left-inner-section'>
    <hr className='horizontal-line' />
    <div className='editorial-description-wrapper'>
    <span className='editorial-description-bold'>Editor-in-Chief</span>
    <span className='editorial-description-light'>David Vlahov</span>
    </div>
    <div className='editorial-description-inner-wrapper'>
        <div className='editorial-description-inner-container'>
            <div className='editorial-description-inner-items'>
            <img className='editorial-description-inner-icon' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABlElEQVR4nO3WTYhOURzH8Y8MycICUZqasFAkCy87ZjVZYUFioazIwlIWFmQxykJix3JmN43kZaNkoawUKTaiUF5CUuQ9uvV76nSzUM/cs9Dzq9O9539P5/u/v3vu+R8GGmhmdAy/i3ZRRW3E91YCY7XgC/Ak0Gu5vsOcWglMBnoX47m/UAu+L8BPWIWHNe1fgY8B7seawv6hruFDuBPgVGInato/HthzLEysmv1b8BO/MJpYz/63Xdu/CC8CO17Ee/Y/wm6sxKwuErgU0G3MLuI9+8v2ATdxGutmAn6wmHik9WwZtsWJq3hZJPI0zvWl1ficCff+w/gDGfsF6/uFz8P94o3e4wZOYReWt8ZvwteMbTaqvjWCk7iO13/51s0W3NOSYpGe05GGsQPPAjqcePP73SoWaafFaCl+4BsWJ3Ym8FdZlJ3qSGDT6e9JvzkTbFZBDwLcjrWphk3/UA34hsDeZNE9Tn9CJZ0P8GxxArqH+TXgc1PvG+jlogC1d8bOtLO1BzQVcauKutJK4GhN+ED/r/4A82V/2k8ioQsAAAAASUVORK5CYII="/>
            <div className='editorial-description-innner-items-wrapper'>
                <span className='editorial-description-inner-bold'>Impact factor</span>
                <span className='editorial-description-inner-light'>{currentItem?.impactFactor}</span>
            </div>
            </div>
            <div className='editorial-description-inner-items'>
            <img className='editorial-description-inner-icon' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VsQmAMBBFXyU4go172DmNKziLrbvYuYaNG4jFiWAbiObiEbgHv0suDz5HwHHikCe5zgcHaKc8Aa0KkgVSKbcCsRbQwgVEq4IZqP+oIMQ9ZAVaSwEBdqDPJSCROYHRUuAAhhwCMYM2oPtwLxkBFqCxWsMJqF4+rirwFReQYr9jsRJwHP7iAk2H4VJNwxj2AAAAAElFTkSuQmCC" />
            <div className='editorial-description-innner-items-wrapper'>
                <span className='editorial-description-inner-bold'>Submission to first decision (median)</span>
                <span className='editorial-description-inner-light'>{currentItem?.submission}</span>
            </div>
            </div>
        </div>
        <div className='editorial-description-inner-container'>
            <div className='editorial-description-inner-items'>
            <img className='editorial-description-inner-icon' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAl0lEQVR4nO3VsQ2AIBCF4VfYO4Nr6CgOo8Mwiq7BDPQWZ0zoFMOZOy8of2JiQfQLCgC12jlSvsoBSPcIQBn33wZIVt5PSNYA6dQAoyWgAxCsAA2AlYEVB8zM2RIFDAA2K0ALwCeWmHsD4BIv9xGnDrgae3yOHvepAibJ5xITsMQlaQIIcTOCFWDMHKcG4FTecUxWgNq/2gHsXgINGm4AxwAAAABJRU5ErkJggg==" />
            <div className='editorial-description-innner-items-wrapper'>
                <span className='editorial-description-inner-bold'>5 year impact factor</span>
                <span className='editorial-description-inner-light'>{currentItem?.impactYear}</span>
            </div>
            </div>
            <div className='editorial-description-inner-items'>
            <img className='editorial-description-inner-icon' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjklEQVR4nO3VMQ6AIBBE0X8FEwtvSqkHtLHxOmNjZcAALiiGSbYz8CK7AD0fjjJqA8Y3AQJ2YLIExMYcoUzAZnUcygQMwGrxJ5QJwAqhBz3gm45XAaoBCOXfABl9400HqB8B/uZxN7M+12pCF7l5McAVEdq8+BguZz1dp62bUImPzv8AqWkfIKNqD9BDjRx6LtvxWuWjQAAAAABJRU5ErkJggg==" />
            <div className='editorial-description-innner-items-wrapper'>
                <span className='editorial-description-inner-bold'>Downloads</span>
                <span className='editorial-description-inner-light'>{currentItem?.downloads}</span>
            </div>
            </div>
        </div>
    </div>
        </div>
        <span>Societies and partnerships</span>
        <div className='editorial-medium-card'>
        <div className='editorial-medium-card-right-item'>
        <span className='editorial-medium-card-right-item-text'>New York Academy of Medicine (NYAM)</span>
        <img className='editorial-medium-card-right-item-icon' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAoUlEQVR4nO2VMQ7DIAxF3yWMeqScOwwZ2qkZstODECFlQFFwTKBT8iUPSOa/L1sCeFSpAfgBsVA+6/WFnkkDBMU8AqMBkKqo04YDCfD9F8AB83Zn7g2QLPkCvHoC5MCcXgC3G0s654sfWwBSSG6WBpBWcw3glLGwG5GvBUhF8ktLfhuSNwEm4GMwvwyo0Q0A4eS5joZKHuqH0wIJm8cjzFoBlbCPZdUo6dUAAAAASUVORK5CYII=" />
    </div>
        </div>
       </div>
       <hr className='horizontal-line' />
        <div className='editorial-medium-card-right-item-bottom'>
        {/* <picture style={{padding:'20px', borderRadius:'4px'}}>
                <source width="158" height="210" srcSet={`https://media.springernature.com/w158/springer-static/cover-hires/journal/${pub}?as=webp, https://media.springernature.com/w316/springer-static/cover-hires/journal/${pub}?as=webp 2x`} />
                <img width="88"  style={{borderRadius:'6px'}} height="117" data-test="darwin-journal-cover" src={`https://media.springernature.com/w88/springer-static/cover-hires/journal/${pub}?as=webp" srcSet="https://media.springernature.com/w176/springer-static/cover-hires/journal/${pub}?as=webp 2x`} alt="" />
        </picture> */}
        <div className='editorial-medium-card-right-item'>
        <span className='editorial-medium-card-bottom-right-item-title'>Latest issue</span>
        <div className='editorial-medium-card-right-item-container'>
        <div className='editorial-medium-card-right-item-wrapper'>
    <span className='editorial-medium-card-bottom-right-item-text'>February 2024 </span>
    <span className='editorial-medium-card-bottom-right-item-text'>|</span>
    <span className='editorial-medium-card-bottom-right-item-text-blue'>Volume 101, Issue 1</span>
        </div>
        <a href={`https://www.editorialmanager.com/${currentItem?.acronym?.toLowerCase()}`} className="view-script-btn-last" target="_blank" rel="noopener">
        View all volumes and issues<span className="u-visually-hidden"> (this opens in a new tab)</span>
        <img className='view-script-icon' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAj0lEQVR4nO3YsQnDMBBA0Z8sEJO0tz+eII3JACkcu3DnYRIM2iCFdcd/oFbwOSSEQJIkSerUCDyBG8nNwBd4Z48JYG8xR9SdxKJqzMeYjifzILGyMYsxlSdzAda24ZlrBoZ/Qq7t4KUPOUtUOPRR4RoOIzpR4vEYFZ7zYUQnSkzisFX5fHgBU/YISZIkkcsPwUZ+ckKpJNQAAAAASUVORK5CYII="></img>
                </a>
        </div>
    </div>
        </div>
        <hr className='horizontal-line' />
        <div className='editorial-medium-card-right-item-bottom'>
        <div className='editorial-medium-card-right-item'>
        <span className='editorial-medium-card-bottom-right-item-title-bottom'>Latest articles</span>
        <hr className='horizontal-line' />
        <div className='editorial-medium-card-right-item-container'>
        </div> 
        <div className='editorial-medium-card-right-item-container'>
        <div className='editorial-medium-card-right-item-wrapper-bottom'>
    {/* <a className='editorial-medium-card-bottom-right-item-text-bold-bottom' href={currentItem?.art1TitleLink}>{currentItem?.art1Title}</a> */}
    <p className='editorial-medium-card-bottom-right-item-text-light'>{currentItem?.art1Content}</p>
    <div className='editorial-medium-card-bottom-right-wrapper'>
        <span className='editorial-medium-card-bottom-right-item-caption-bold'>Original Article</span> <span className='editorial-medium-card-bottom-right-item-caption-light'>|</span><span className='editorial-medium-card-bottom-right-item-caption-bold'>{currentItem?.art1Year}</span>
    </div>
        </div>
      <div className='editorial-medium-card-bottom-right-container'>
      {/* <img loading="lazy" className='editorial-medium-card-bottom-right-img' src={cartAdImg5} width="110" height="61" alt="" /> */}
      </div>
        </div>

        <hr className='horizontal-line' />
        <div className='editorial-medium-card-right-item-container'>
        <div className='editorial-medium-card-right-item-wrapper-bottom'>
    {/* <a className='editorial-medium-card-bottom-right-item-text-bold-bottom' href={currentItem?.art2TitleLink}>{currentItem?.art2Title}</a> */}
    <p className='editorial-medium-card-bottom-right-item-text-light'>{currentItem?.art2Content}</p>
    <div className='editorial-medium-card-bottom-right-wrapper'>
        <span className='editorial-medium-card-bottom-right-item-caption-bold'>Original Article</span> <span className='editorial-medium-card-bottom-right-item-caption-light'>|</span><span className='editorial-medium-card-bottom-right-item-caption-bold'>{currentItem?.art2Year}</span>
    </div>
        </div>
      <div className='editorial-medium-card-bottom-right-container'>
      {/* <img loading="lazy" className='editorial-medium-card-bottom-right-img' src={cartAdImg5} width="110" height="61" alt="" /> */}
      </div>
        </div>
        <hr className='horizontal-line' />
        <div className='editorial-medium-card-right-item-container'>
        <div className='editorial-medium-card-right-item-wrapper-bottom'>
    {/* <a className='editorial-medium-card-bottom-right-item-text-bold-bottom' href={currentItem?.art3TitleLink}>{currentItem?.art3Title}</a> */}
    <p className='editorial-medium-card-bottom-right-item-text-light'>{currentItem?.art3Content}</p>
    <div className='editorial-medium-card-bottom-right-wrapper'>
        <span className='editorial-medium-card-bottom-right-item-caption-bold'>Original Article</span> <span className='editorial-medium-card-bottom-right-item-caption-light'>|</span><span className='editorial-medium-card-bottom-right-item-caption-bold'>{currentItem?.art3Year}</span>
    </div>
        </div>
      <div className='editorial-medium-card-bottom-right-container'>
      {/* <img loading="lazy" className='editorial-medium-card-bottom-right-img' src={cartAdImg5} width="110" height="61" alt="" /> */}
      </div>
        </div>
        <hr className='horizontal-line' />
        <div className='editorial-medium-card-right-item-container'>
        <div className='editorial-medium-card-right-item-wrapper-bottom'>
    {/* <a className='editorial-medium-card-bottom-right-item-text-bold-bottom' href={currentItem?.art4TitleLink}>{currentItem?.art4Title}</a> */}
    <p className='editorial-medium-card-bottom-right-item-text-light'>{currentItem?.art4Content}</p>
    <div className='editorial-medium-card-bottom-right-wrapper'>
        <span className='editorial-medium-card-bottom-right-item-caption-bold'>Original Article</span> <span className='editorial-medium-card-bottom-right-item-caption-light'>|</span><span className='editorial-medium-card-bottom-right-item-caption-bold'>{currentItem?.art4Year}</span>
    </div>
        </div>
      <div className='editorial-medium-card-bottom-right-container'>
      {/* <img loading="lazy" className='editorial-medium-card-bottom-right-img' src={cartAdImg5} width="110" height="61" alt="" /> */}
      </div>
        </div>
        <hr className='horizontal-line' />
        <div className='editorial-medium-card-right-item-container'>
        <div className='editorial-medium-card-right-item-wrapper-bottom'>
    {/* <a className='editorial-medium-card-bottom-right-item-text-bold-bottom' href={currentItem?.art5TitleLink}>{currentItem?.art5Title}</a> */}
    <p className='editorial-medium-card-bottom-right-item-text-light'>{currentItem?.art5Content}</p>
    <div className='editorial-medium-card-bottom-right-wrapper'>
        <span className='editorial-medium-card-bottom-right-item-caption-bold'>Original Article</span> <span className='editorial-medium-card-bottom-right-item-caption-light'>|</span><span className='editorial-medium-card-bottom-right-item-caption-bold'>{currentItem?.art5Year}</span>
    </div>
        </div>
        </div>
        <hr className='horizontal-line' />
        <div className='editorial-medium-card-right-item-container-last'>
        {/* <a href={currentItem?.allArticlesLink} className="view-script-btn-last" target="_blank" rel="noopener">
        View all articles
        <img className='view-script-icon' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAj0lEQVR4nO3YsQnDMBBA0Z8sEJO0tz+eII3JACkcu3DnYRIM2iCFdcd/oFbwOSSEQJIkSerUCDyBG8nNwBd4Z48JYG8xR9SdxKJqzMeYjifzILGyMYsxlSdzAda24ZlrBoZ/Qq7t4KUPOUtUOPRR4RoOIzpR4vEYFZ7zYUQnSkzisFX5fHgBU/YISZIkkcsPwUZ+ckKpJNQAAAAASUVORK5CYII="></img>
                </a> */}
                <span className='editorial-medium-card-bottom-right-item-caption-last-left'>This journal has </span>
                <div className='editorial-medium-card-bottom-right-item-caption-last-left-wrapper'>
                {/* <a className='editorial-medium-card-bottom-right-item-caption-last-middle' href={`https://link.springer.com/search?query=&search-within=Journal&package=openaccessarticles&facet-journal-id=${pub}`}>{currentItem?.openAccessNo} open access articles allArticlesLink
                <img className='view-script-icon-last-right' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAj0lEQVR4nO3YsQnDMBBA0Z8sEJO0tz+eII3JACkcu3DnYRIM2iCFdcd/oFbwOSSEQJIkSerUCDyBG8nNwBd4Z48JYG8xR9SdxKJqzMeYjifzILGyMYsxlSdzAda24ZlrBoZ/Qq7t4KUPOUtUOPRR4RoOIzpR4vEYFZ7zYUQnSkzisFX5fHgBU/YISZIkkcsPwUZ+ckKpJNQAAAAASUVORK5CYII="></img>
                </a> */}
                </div>
        </div>
        {currentItem?.journalUpdate1Content?.length>0&&<div className='editorial-last-left-cards'>
            <span className='editors-bottom-title'>Journal updates</span>
            <div className='editorial-last-left-card'>
                {/* <a className='editorial-medium-card-bottom-right-item-text-bold-bottom' href={currentItem?.journalUpdateLink1}>
                {currentItem?.journalUpdateCaption1}
                </a> */}
                <p className='editorial-medium-card-bottom-right-item-text-light'>
                {currentItem?.journalUpdate1Content}
                </p>
            </div>
            <div className='editorial-last-left-card'>
                {/* <a className='editorial-medium-card-bottom-right-item-text-bold-bottom' href={currentItem?.journalUpdateLink2}>
                {currentItem?.journalUpdateCaption2}
                </a> */}
                <p className='editorial-medium-card-bottom-right-item-text-light'>
                {currentItem?.journalUpdate2Content}
                </p>
            </div>
            <div className='editorial-last-left-card'>
                {/* <a className='editorial-medium-card-bottom-right-item-text-bold-bottom' href={currentItem?.journalUpdateLink3}>
                {currentItem?.journalUpdateCaption3}
                </a> */}
                <p className='editorial-medium-card-bottom-right-item-text-light'>
                {currentItem?.journalUpdate3Content}
                </p>
            </div>
        </div>}
        <div className='editorial-journal-info-section'>
        <div className='editorial-journal-info-top'>
            <span className='editors-bottom-title'>Journal information</span>
            <div className='editorial-journal-info-top-wrapper'>
                <span className='editorial-journal-info-bottom-caption'>Electronic ISSN</span>
                <span className='editorial-journal-info-bottom-caption'>{currentItem?.electronicISN}</span>
            </div>
            <div className='editorial-journal-info-top-wrapper'>
                <span className='editorial-journal-info-bottom-caption'>Print ISSN</span>
                <span className='editorial-journal-info-bottom-caption'>{currentItem?.printISN}</span>
            </div>
        </div>
        </div>
        <div className='editorial-journal-info-section'>
        <div className='editorial-journal-info-bottom'>
            <span className='editors-bottom-title-last'>Journal information</span>
           <div className='editorial-journal-info-bottom-wrapper'>
           <div className='editorial-journal-info-top-wrapper'>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo1}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo2}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo3}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo4}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo5}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo6}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo7}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo8}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo9}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo10}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo11}</span>
           </div>
            <div className='editorial-journal-info-top-wrapper'>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo12}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo13}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo14}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo15}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo16}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo17}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo18}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo19}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo20}</span>
           </div>
            <div className='editorial-journal-info-top-wrapper'>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo21}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo22}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo23}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo24}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo25}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo26}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo27}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo28}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo29}</span>
           <span className='editorial-journal-info-bottom-caption'>{currentItem?.journalInfo30}</span>
            </div>
           </div>
        </div>
        </div>
    </div>
        </div>
       </div>
       
    </div>
    <div className='editorial-footer'>
    <div className='editorial-footer-top'>
    <div className='editorial-footer-top-items'>
    <div className='editorial-footer-top-item'>
    <h1 className='editorial-footer-top-item-title'>Discover content</h1>
    <p className='editorial-footer-top-item-description'>Journals A-Z</p>
    <p className='editorial-footer-top-item-description'>Books A-Z</p>
    </div>
    <div className='editorial-footer-top-item'>
    <h1 className='editorial-footer-top-item-title'>Publish with us</h1>
    <p className='editorial-footer-top-item-description'>Publish your research</p>
    <p className='editorial-footer-top-item-description'>Open access publishing</p>
    </div>
    <div className='editorial-footer-top-item'>
    <h1 className='editorial-footer-top-item-title'>Products and services</h1>
    <p className='editorial-footer-top-item-description'>Our products</p>
    <p className='editorial-footer-top-item-description'>Librarians</p>
    <p className='editorial-footer-top-item-description'>Societies</p>
    <p className='editorial-footer-top-item-description'>Partners and advertisers</p>
    </div>
    <div className='editorial-footer-top-item'>
    <h1 className='editorial-footer-top-item-title'>Our imprints</h1>
    <p className='editorial-footer-top-item-description'>Nature Portfolio</p>
    <p className='editorial-footer-top-item-description'>BMC</p>
    <p className='editorial-footer-top-item-description'>Palgrave Macmillan</p>
    <p className='editorial-footer-top-item-description'>Apress</p>
    </div>
    <hr className='editorial-footer-divider'/>
    <div className='editorial-footer-top-bottom'>
    </div>
    <div className='editorial-footer-bottom-bottom'>
    <span className='bottom-footer-text'>62.133.61.131</span>
    <span className='bottom-footer-text'>Not affiliated</span>
    </div>
    <span style={{color:'white'}}>© The New York Academy of Medicine</span>

    </div>
    </div>
    </div>
        </div>:
        addToCartState.state?
        <div className='add-to-cart-modal'>
        <div className='add-to-cart-inner-top'>
        <p className='added-to-your-cart'>Added to your cart</p>
        <div className='add-to-cart-inner-right'>
        <span className='close-cart'>Close</span><FaTimes className='close-cart-icon' onClick={()=>setAddToCartState({state:false,name:'',cost:0})} />
        </div>
        </div>
        <div className='add-to-cart-btn-group'>
        <Link to={`/public/cart`} className='go-to-cart-btn'>Go to cart</Link>
        <button className='go-to-checkout-btn'>Go to checkout</button>
        </div>
        </div>:
        <div className='preview-main'>
            <img src={currentCartItemImage} className='image-preview' alt=''/>
            <FaTimes onClick={()=>setPreview(false)}/>
        </div>
    }
    </>)
};

SpringerJournal.propTypes = {};

export { SpringerJournal };
