import {Routes, BrowserRouter, Route, Navigate} from 'react-router-dom'
import SpringerHome from './Pages/Home/SpringerHome'
import { Shopping } from './shopping/Shopping'
import { CartItem } from './shopping/CartItem'
import { Cart } from './shopping/Cart'
import { Checkout } from './shopping/Checkout'
import { SpringerRegister } from './Pages/Home/Springer/SpringerRegister'
import { SpringerJournal } from './Pages/Home/Springer/SpringerJournal'
import { SpringerLoginOptions } from './Pages/Home/Springer/SpringerLoginOptions';
import { About } from './Pages/Home/Springer/terms/about'

const App = ()=>{
  const user = JSON.parse(localStorage.getItem('user'))
  return(
    <BrowserRouter>
    <Routes>
      <Route path = '/' element = {<Navigate to='/journal/11001'/>}/>
      <Route path = '/journal/:pub' element = {<SpringerJournal/>}/>
      <Route path = '/about/declarations' element = {<About/>}/>
    </Routes>
    </BrowserRouter>
  )
}
export default App